import React, { Component } from "react";
import { connect } from "react-redux";
import datepicker from "js-datepicker";
import moment from 'moment-timezone';
import { getCLASSEST } from '../../../redux/actions';

class ActivityFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: this.props.user.admin,
      teacher: this.props.user.email
    };
  }

  changeTeacher(event) {
    this.setState({ teacher: event.target.value });
  }

  async findSessions(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;
    /*const from_date = new Date(form.from_date.value)
    const to_date = new Date(form.to_date.value)*/
    // console.log(moment(`${from_date.getFullYear()}-${from_date.getMonth()}-${from_date.getDate()} 00:00:00Z`).tz('GMT').format());
    const data = {
        fechaIni: moment(form.from_date.value).format('YYYY-MM-DD'),
        fechaFin: moment(form.to_date.value).format('YYYY-MM-DD'),
        status: form.status.value
    };
    // console.log(data)
	let res = await getCLASSEST(data);
	let classes = [];
	if(res.classes) {
		if(!this.state.admin) {
			classes = res.classes.filter(classe => {
				console.log(classe);
				if(classe.EMAIL_TEACHER == this.state.teacher) {
					return true;
				} else {
					return false;
				}
			});
		} else {
			classes = res.classes;
		}
	} else {
		classes= [];
	}
    this.props.getClasses(classes);
    this.setState({ loading: false });
  }

	render() {
		return (
			<div className="admin-session-filter column">
				<form className="row-responsive" onSubmit={this.findSessions.bind(this)} >
					<div className="input-group align-center">
						<label htmlFor="duration">From Date:</label>
						<input type="text" name="from_date" className="input input-text" id="from-date" placeholder="Click to pick a date" readOnly />
					</div>
					<div className="input-group align-center">
						<label htmlFor="duration">To Date:</label>
						<input type="text" name="to_date" className="input input-text" id="to-date" placeholder="Click to pick a date" readOnly />
					</div>
					<div className="input-group align-center">
						<label htmlFor="status">Status:</label>
						<select name="status" id="status" className="input input-select">
							<option value="0">ALL</option>
							<option value="1">OPEN</option>
							<option value="2">CANCELLED BY STUDENT</option>
							<option value="3">FINISHED</option>
							<option value="4">FAILED</option>
							<option value="5">CANCELLED BY TEACHER</option>
							<option value="6">NO FEEDBACK</option>
						</select>
					</div>
					<div className="input-group align-center">
						{!this.state.loading ?
						<button className="btn btn-orange">
							FIND &nbsp; <i className="fas fa-search" />
						</button> :
						<button className="btn btn-orange">
							<i className="fas fa-spinner fa-spin" />
						</button>}
					</div>
				</form>
			</div>
		);
	}

	componentDidMount() {
		const fromDate = datepicker("#from-date", {
			dateSelected: new Date()
		});
		const tiDate = datepicker("#to-date", {
			dateSelected: new Date()
		});
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps)(ActivityFilter);
