import React, { Component } from "react";
import { connect } from "react-redux";
import datepicker from "js-datepicker";
import moment from "moment-timezone";
import { getSESSIONS, getTeachers } from "../../redux/actions";

// Web Components
import Aside from "../../components/user/aside";
import Teachers from "../../components/user/schedule/teachers";
import Modal from "../../components/user/schedule/modal";
import OtherDayModal from "../../components/user/schedule/otherDayModal";
import Paginator from "../../components/paginator";

// Images
import Images from "../../img/index";

class Schedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			defautlDate: moment()
				.tz(this.props.user.utc)
				.format("dd MMM DD YYYY"),
			sessions: [],
			teachers: [],
			page: 1
		};
		this.getTeachers();
	}

	async getTeachers() {
		const teachers = await getTeachers();
		// console.log(teachers);
		if(teachers) {
			this.setState({teachers: teachers.teachers})
		}
	}

	submit(event) {
		event.preventDefault();
		const form = event.target;
		// const dateGMT = new Date(form.date.value);
		const date = moment(form.date.value).format('YYYY-MM-DD');
		// console.log(date);
		// console.log(date);
		const duration = form.duration.value;
		const username = this.props.user.email;
		const teacher = form.teacher.value;
		const data = {
			date,
			duration,
			teacher,
			username
		};
		this.findSessions(data);
	}

	async findSessions(data) {
		this.setState({ loading: true });
		let sessions = await getSESSIONS(data);
		this.setState({ loading: false });
		if (sessions) {
			if(sessions.retry) {
				this.setState({ otherDay: sessions.sessions[0].DIA });
				document.getElementById("modal-otherday").classList.remove("close-modal");
				document.getElementById("modal-otherday").classList.remove("hidden");
			}
			if(sessions.sessions) {
				const filtro = sessions;
				filtro.sessions = sessions.sessions.filter(sesion => moment(sesion.INI).diff(moment(), 'hours') >= 24);
				if(filtro.sessions.length <= 0) {
					filtro.message = "No sessions founded";
				}
				this.setState({ sessions: filtro });
			}
		}
	}

	setSession(session) {
		this.setState({ session });
	}

	setpage(page) {
		this.setState({ page: page });
	}

  render() {
    let teachers = [], sessions = [];
    if(this.state.teachers) {
		teachers = this.state.teachers;
    }
    
    if (this.state.sessions.sessions) {
		sessions = this.state.sessions.sessions;
    }

    return (
      	<div className="user-admin justify-center">
        	<div className="container column">
          		<div className="white-space-32" />
          		<div className="row-responsive break-row">
        			<Aside current={"active-schedule"} />
            		{/*  Load Views Here */}
            		<div className="user-area">
              			<div className="schedule-page column">
							<h2 className="color-orange" id="to-schedule">
								SCHEDULE A SESSION
							</h2>
                			<div className="white-space-32" />
							<div className="align-center">
								<img src={Images["one"]} alt="First Step" title="First Step" />
								<h3 className="color-orange">
									&nbsp; Choose a day, duration and teacher
								</h3>
							</div>
                			<div className="white-space-16"></div>
							<form className="search-container row-responsive" onSubmit={this.submit.bind(this)} >
								<div className="input-group auto align-center">
									<label htmlFor="date">Date:</label>
									<input type="text" name="date" id="date" readOnly
										className="input input-date input-text" placeholder="Click to pick a date" />
								</div>
								<div className="input-group auto align-center">
									<label htmlFor="duration">Duration:</label>
									<select name="duration" id="duration" className="input input-select" defaultValue="60">
									<option value="30">30 Minutes</option>
									<option value="45">45 Minutes</option>
									<option value="60">1 Hour</option>
									<option value="75">1:15 Hour</option>
									<option value="90">1:30 Hour</option>
									<option value="105">1:45 Hour</option>
									<option value="120">2 Hour</option>
									</select>
								</div>
								<div className="filter-container auto input-group align-center">
									<label htmlFor="teacher">Teacher:</label>
									<select name="teacher" className="input input-select" id="teacher-select">
									<option value={""}>{"ALL"}</option>
									{teachers.map((teacher, index) => (
										<option value={teacher.NAME} key={index}>
										{teacher.NAME}
										</option>
									))}
									</select>
								</div>
								<div className="input-group auto align-center">
									{!this.state.loading ?
									<button id="find-schedule" type="submit" className="btn btn-orange">
										&nbsp; FIND &nbsp;  <i className="fas fa-search" />
									</button> :
									<button id="find-schedule" type="submit" className="btn btn-orange">
										<i className="fas fa-spinner fa-spin" />
									</button>}
								</div>
							</form>               
                			<div className="white-space-24"></div>
							<div className="avaible-sessions column">
								<h3 className="color-orange">&nbsp; Available Sessions</h3>
								<div className="white-space-32" />
								<Teachers
									loading = {this.props.sessions_loading}
									sessions = {this.state.sessions.sessions}
									timezone = {this.props.user.utc}
									setSession = {this.setSession.bind(this)}
									page={this.state.page} />
								{ this.state.sessions.message !== "OK" ? this.state.sessions.message : null }
								<div className="white-space-16"></div>
								{sessions.length > 6 ?
									<Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(sessions.length / 6)} />
									: null
								}
							</div>
              			</div>
            		</div>
         		</div>
        	</div>
        	<Modal session = {this.state.session} timezone = {this.props.user.utc} user = {this.props.user}/>
        	<OtherDayModal day={this.state.otherDay}/>
      	</div>
    	);
  	}

  componentDidMount() {
    const nextDay = new Date().getDate() + 1;
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    const picker = datepicker(".input-date", {
      dateSelected: new Date(currentYear, currentMonth, nextDay),
      minDate: new Date(currentYear, currentMonth, nextDay)
    });
  }
}
function mapStateToProps(state) {
  return {
    user: state.user,
    sessions_loading: state.sessions_loading
  };
}
export default connect(mapStateToProps)(Schedule);
