import React, { Component } from 'react';
import Aside from '../../components/user/aside';
import { getUSERS } from '../../redux/actions';

// Web Component
import UsersTable from "../../components/admin/tables/users-table";
import Modal from "../../components/admin/modals/users";
import DinamicModal from "../../components/dinamicModal/dinamicModal";
import Sessions from "../../components/admin/modals/userSessions";
import Paginator from "../../components/paginator";

class AdminUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loadingUsers: null,
            page: 1
        }    
        this.getAllUsersMethod();
    }

    async getAllUsersMethod() {
        this.setState({ loadingUsers: true });
        const result = await getUSERS();
        if ( Array.isArray(result.users) ) {
            this.setState({ users: result.users });
        } else {
			this.setState({ users: [] });
		}
		this.setState({ loadingUsers: false });
    }   

	searchUsers() {
		const input = document.getElementById('search_users');
		if (input) {
			const filter = (input.value || '').toLowerCase();
			const usersSearch = this.state.users.filter(({USERNAME, NAME}) => {
				if ((USERNAME || '').toLowerCase().indexOf(filter) !== -1) {
					console.log('email', filter);
					return true;
				}
				if ((NAME || '').toLowerCase().indexOf(filter) !== -1) {
					console.log('email', filter);
					return true;
				}
				return false;
			});
			this.setState({usersSearch});
		}
	}

	currentUser(user) {
        this.setState({ModalUser: user})
    }

    setpage(page) {
        this.setState({page});
    }

    render() {
		let users = [];
		if (Array.isArray(this.state.usersSearch)) {
			users = this.state.usersSearch
		} else {
			if (Array.isArray(this.state.users)) {
				users = this.state.users;
			}
		}
        return(
            <div className="user-admin justify-center">
                <div className="container column">
                    <div className="white-space-32" />
                    <div className="row-responsive break-row">
                        <Aside current={"active-users"} />
                        <div className="admin-area">
                            <div className="sessions-page column">
                                <h2 className="color-orange" id="to-users">
                                    USERS
                                </h2>
                                <div className="white-space-16"></div>
								<div className="row user-search">
									<input id="search_users" className="input input-text"></input>
									<div className="row btn-user-search">
										<button className="btn btn-orange" onClick={this.searchUsers.bind(this)}>
											FIND &nbsp; <i className="fas fa-search" />
										</button>
									</div>
								</div>
								<div className="white-space-16"></div>
                                <p className="swipe-to-left">
                                    Swipe to the right to see more
                                </p>
                                <div className="white-space-16"></div>
                                {
                                    this.state.loadingUsers ? (
                                        <div>
                                            <i className="fas fa-spin fa-spinner"></i>
                                        </div>                                        
                                    ) :
                                    ( <UsersTable users = {users} currentUser = {this.currentUser.bind(this)} page = {this.state.page}/> )
                                }
                                <div className="white-space-16"></div>
                                {users.length > 12 ?
                                    <Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(users.length / 12)} /> : null} 
                            </div>
                        </div>
                    </div>
                    <div className="white-space-32" />
                    <Modal ModalUser={this.state.ModalUser}/>
					<DinamicModal
						idModal="modal-users-sessions"
						title="SESSIONS"
						sizeModalContainer="huge">
						<Sessions user={this.state.ModalUser}/>
					</DinamicModal>
                </div>
            </div>
        );
    }
}

export default AdminUsers;