/* ==========================================================================
    USERS
========================================================================== */

export const LOGIN = "LOGIN";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT = "LOGOUT";

export const REGISTER = "REGISTER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const UPDATEUSER = "UPDATEUSER";
export const UPDATEUSER_ERROR = "UPDATEUSER_ERROR";
export const UPDATEUSER_SUCCESS = "UPDATEUSER_SUCCESS";

export const UPDATEROLE = "UPDATEROLE";
export const UPDATEROLE_ERROR = "UPDATEROLE_ERROR";
export const UPDATEROLE_SUCCESS = "UPDATEROLE_SUCCESS";

export const GET_USERS = "GET_USERS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";


/* ==========================================================================
    NATIONALITIES
========================================================================== */

export const GET_NATIONALITIES = "GET_NATIONALITIES";
export const GET_NATIONALITIES_ERROR = "GET_NATIONALITIES_ERROR";
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";

/* ==========================================================================
    TIMEZONES
========================================================================== */

export const GET_UTC = "GET_UTC";
export const GET_UTC_ERROR = "GET_UTC_ERROR";
export const GET_UTC_SUCCESS = "GET_UTC_SUCCESS";

/* ==========================================================================
    TOPICS
========================================================================== */

export const GET_TOPICS = "GET_TOPICS";
export const GET_TOPICS_ERROR = "GET_TOPICS_ERROR";
export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";

/* ==========================================================================
    SESSIONS
========================================================================== */

export const GET_SESSIONS = "GET_SESSIONS";
export const GET_SESSIONS_ERROR = "GET_SESSIONS_ERROR";
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";

export const GET_USER_SESSIONS = "GET_USER_SESSIONS";
export const GET_USER_SESSIONS_ERROR = "GET_USER_SESSIONS_ERROR";
export const GET_USER_SESSIONS_SUCCESS = "GET_USER_SESSIONS_SUCCESS";

export const GET_NEXTCLASS = "GET_NEXTCLASS";
export const GET_NEXTCLASS_ERROR = "GET_NEXTCLASS_ERROR";
export const GET_NEXTCLASS_SUCCESS = "GET_NEXTCLASS_SUCCESS";

export const RESERVE_SESSIONS = "RESERVE_SESSIONS";
export const RESERVE_SESSIONS_ERROR = "RESERVE_SESSIONS_ERROR";
export const RESERVE_SESSIONS_SUCCESS = "RESERVE_SESSIONS_SUCCESS";

export const UPDATE_COMMENTS = "UPDATE_COMMENTS";
export const UPDATE_COMMENTS_ERROR = "UPDATE_COMMENTS_ERROR";
export const UPDATE_COMMENTS_SUCCESS = "UPDATE_COMMENTS_SUCCESS";

/* ==========================================================================
    CREDITS
========================================================================== */

export const GET_CREDITS = "GET_CREDITS";
export const GET_CREDITS_ERROR = "GET_CREDITS_ERROR";
export const GET_CREDITS_SUCCESS = "GET_CREDITS_SUCCESS";

export const BUY_CREDITS = "BUY_CREDITS";
export const BUY_CREDITS_ERROR = "BUY_CREDITS_ERROR";
export const BUY_CREDITS_SUCCESS = "BUY_CREDITS_SUCCESS";

export const OPTIONPAY = "OPTIONPAY";

/* ==========================================================================
    TEACHERS
========================================================================== */

export const GET_TEACHERS = "GET_TEACHERS";
export const GET_TEACHERS_ERROR = "GET_TEACHERS_ERROR";
export const GET_TEACHERS_SUCCESS = "GET_TEACHERS_SUCCESS";

export const GET_CALENDARS = "GET_CALENDARS";
export const GET_CALENDARS_ERROR = "GET_CALENDARS_ERROR";
export const GET_CALENDARS_SUCCESS = "GET_CALENDARS_SUCCESS";

export const ADD_CALENDARS = "ADD_CALENDARS";
export const ADD_CALENDARS_ERROR = "ADD_CALENDARS_ERROR";
export const ADD_CALENDARS_SUCCESS = "ADD_CALENDARS_SUCCESS";

export const DEL_CALENDAR = "DEL_CALENDAR";
export const DEL_CALENDAR_ERROR = "DEL_CALENDAR_ERROR";
export const DEL_CALENDAR_SUCCESS = "DEL_CALENDAR_SUCCESS";

export const SAVE_CALENDAR = "SAVE_CALENDAR";
export const SAVE_CALENDAR_ERROR = "SAVE_CALENDAR_ERROR";
export const SAVE_CALENDAR_SUCCESS = "SAVE_CALENDAR_SUCCESS";

export const GET_HOURS = "GET_HOURS";
export const GET_HOURS_ERROR = "GET_HOURS_ERROR";
export const GET_HOURS_SUCCESS = "GET_HOURS_SUCCESS";

export const GET_CLASSES_TEACHER = "GET_CLASSES_TEACHER";
export const GET_CLASSES_TEACHER_ERROR = "GET_CLASSES_TEACHER_ERROR";
export const GET_CLASSES_TEACHER_SUCCESS = "GET_CLASSES_TEACHER_SUCCESS";