import React, { Component } from "react";
import { connect } from "react-redux";
import { getCalendars } from "../../../redux/actions";

class CalendarFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      admin: this.props.user.admin,
      teacher: this.props.user.email
    };
  }

  componentDidMount() {
    this.load(this.props.user.email);
  }

  componentDidUpdate(prevProps) {
    if(this.props.teachers !== prevProps.teachers) {
      this.load(this.props.user.email);
    }
  }

  async load(username) {
    const data = {
      username: username
    };
    const select = document.getElementById('calendar');
    if(select) {
      select.disabled = true;
      select.style.background = '#eee';
    }
    const calendars = await getCalendars(data);
    const tInfo = this.props.teachers.find(teacher => teacher.USERNAME === username);
    let teacherTZ = 'GMT'
    if(tInfo) {
      teacherTZ = tInfo.UTC;
    };
    console.log(teacherTZ);
    this.props.setCalendars(calendars, teacherTZ);
    if(select) {
      select.disabled = false;
      select.style.background = '#fff';
    }
  }

  changeTeacher(event) {
    this.setState({ teacher: event.target.value });
    this.load(event.target.value);
    document.getElementById('calendar').value = "0"
  }

  render() {
    return (
      <div className="filter-container input-group align-center">
        <label htmlFor="teacher">Teacher:</label>
        <select name="teacher" id="teacher" className="input input-select" onChange={this.changeTeacher.bind(this)} disabled={!this.state.admin} value={this.state.teacher} >
          <option value="0">SELECT A TEACHER</option>
          {this.props.teachers.map((teacher, index) => (
            <option key={index} value={teacher.USERNAME}>
              {teacher.NAME}
            </option>
            ))
          }
        </select>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(CalendarFilter);
