import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUser } from "../../redux/actions";
import { URL_API } from "../../redux/urls";

// Web Components
import Aside from "../../components/user/aside";
import Modal from "../../components/admin/modals/changepass";

// Images
import ImageLoader from "react-loading-image";

class Profile extends Component {
	constructor(props) {
		super(props);
			this.state = {
				message: "",
				email: this.props.user.email,
				skype: this.props.user.skype,
				name: this.props.user.name,
				timezone: this.props.user.utc,
				idImage: this.props.user.idImage,
				image: null,
				nationality: this.props.user.nationality,
				languages: this.props.user.languages,
				experience: this.props.user.experience,
				background: this.props.user.background,
				specialist: this.props.user.specialist,
				telephone: this.props.user.telephone
			};
	}

    changeTime(event) {
        console.log(event.target.value);
        this.setState({ timezone: event.target.value });
		}
		
	changeNationality(event) {
		console.log(event.target.value);
		this.setState({ nationality: event.target.value });
	}

	changeImage() {
  	document.getElementById("profile-image").click();
  }

    handleChange(event) {
        let resizedImage = "";
        const src = event.target;
        let fr = new FileReader();
        fr.onload = function(e) {
          const image = new Image();
          image.onload = function(imageEvent) {
            // Resize the image
            const canvas = document.createElement("canvas");
            const max_size = 300;
            let width = image.width;
            let height = image.height;
            if (width > height) {
              if (width > max_size) {
                height *= max_size / width;
                width = max_size;
              }
            } else {
              if (height > max_size) {
                width *= max_size / height;
                height = max_size;
              }
            }
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(image, 0, 0, width, height);
            resizedImage = canvas.toDataURL("image/png");
    
            document.getElementById("img-profile").src = resizedImage;
            this.setState({imagen: resizedImage});
          }.bind(this);
          image.src = e.target.result;
        }.bind(this);
        document.getElementById("profile-img").style.border = "none";
        if (src.files.length > 0) {
          fr.readAsDataURL(src.files[0]);
        }
      }

    imgError(event) {
        event.target.src =
            "https://via.placeholder.com/250x250?text=CLICK+TO+CHANGE";
    }

    render() {
				let timezones = this.props.utc ? this.props.utc.data : [];
				let nationalities = this.props.nationalities ? this.props.nationalities.data : [];

        return (
            <div className="user-admin justify-center">
                <div className="container column">
                    <div className="white-space-32" />
                    <div className="row-responsive break-row">
                        <Aside current={"active-profile"} />
                        {/*  Load Views Here */}
                        <div className="user-area">
                            <div className="profile-page column">
                            <div className="white-space-32"></div>
                                <h2 className="color-orange" id="to-profile">MY PROFILE</h2>
                                <div className="white-space-32" />
                                <h3 className="color-orange">Personal Info</h3>
                                <div className="white-space-16" />
                                <form className="row-responsive" onSubmit={this.handleUpdate.bind(this)} >
                                    <div className="left column">
                                        <div className="input-group align-center">
                                            <label htmlFor="username">Email:</label>
                                            <input type="email" name="email" disabled className="input input-text" value={this.state.email} onChange={event =>
                                                this.setState({ email: event.target.value })
                                            }/>
                                        </div>
                                        <div className="white-space-16" />
										<div className="input-group align-center">
											<label htmlFor="skype">Telephone:</label>
											<input
												type="phone"
												name="telephone"
												className="input input-text"
												value={this.state.telephone}
												onChange={event =>
													this.setState({ telephone: event.target.value })
												}
											/>
										</div>
										<div className="white-space-16" />
                                        <div className="input-group align-center">
                                            <label htmlFor="skype">Skype ID:</label>
                                            <input type="text" name="skype" className="input input-text" value={this.state.skype}
                                            onChange={event =>
                                                this.setState({ skype: event.target.value })
                                            } />
                                        </div>
                                        <div className="white-space-16" />
                                        <div className="input-group align-center">
                                            <label htmlFor="fullname">Full Name:</label>
                                            <input type="text" name="name" className="input input-text" value={this.state.name}
                                            onChange={event =>
                                                this.setState({ name: event.target.value })
                                            } />
                                        </div>
                                        <div className="white-space-16" />
                                        <div className="input-group align-center">
                                            <label htmlFor="timezone">Time Zone:</label>
                                            <select name="timezone" id="timezone" className="input input-select" value={this.state.timezone} onChange={this.changeTime.bind(this)} >
                                                {this.props.utc_loading ? (
                                                    <option value="0">{"loading..."}</option>
                                                ) : (
                                                    timezones.map((tmz, index) => (
                                                        <option value={tmz.zona} key={index}>
                                                            {tmz.zona}
                                                        </option>
                                                    ))
                                                )}
                                            </select>
                                        </div>
                                        <div className="white-space-16" />
                                        <div className="align-center justify-center">
                                            <label>{this.state.message}</label>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <h3 className="color-orange">
                                            Resume
                                        </h3>
                                        <div className="white-space-16"></div>
                                        <div className="resume column">

											<div className="white-space-16" />
                                        	<div className="input-group align-center">
                                            <label htmlFor="nationality">Nationality:</label>
                                            <select name="nationality" id="nationality" className="input input-select" value={this.state.nationality} onChange={this.changeNationality.bind(this)}>
                                            { this.props.nationalities_loading ? (
                                                <option value="0">{"loading..."}</option>
                                            ) : (
                                                nationalities.map((nat, index) => (
                                                    <option value={nat.alpha_3_code} key={index}>
                                                        {nat.nationality}
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                    </div>

                                    <div className="white-space-16" />
                                    <div className="input-group align-center">
                                            <label htmlFor="languages">Languages:</label>
                                            <input type="text" name="languages" className="input input-text" maxLength="100" value = {this.state.languages} 
                                            onChange={event => this.setState({ languages: event.target.value }) } />
                                    </div>
                                    <div className="white-space-16" />
                                    <div className="input-group align-center">
                                        <label htmlFor="teaching">Teaching <br/> experience:</label>
                                        <input type="text" name="teaching" maxLength="100" className="input input-text" value={this.state.experience} 
                                            onChange={event => this.setState({ experience: event.target.value }) } />
                                    </div>
                                    <div className="white-space-16" />
                                    <div className="input-group align-center">
                                        <label htmlFor="background">Background:</label>
                                        <textarea type="text" name="background" rows="5" maxLength="300" className="input input-text" value = {this.state.background} 
                                            onChange={event => this.setState({ background: event.target.value }) } />
                                    </div>
                                    <div className="white-space-16" />
                                    <div className="input-group align-center">
                                        <label htmlFor="specialist">Specialised<br/>subjects:</label>
                                        <textarea type="text" name="specialist" rows="6" maxLength="350" className="input input-text" value = {this.state.specialist} 
                                            onChange={event => this.setState({ specialist: event.target.value }) } />
                                    </div>

                                </div>
                            </div>


                                    <div className="right column justify-center align-center" id="profile-img-div" >
                                        <h4 className="color-orange">
                                            Profile Picture
                                        </h4>
                                        <div className="white-space-8"></div>
                                        <div className="justify-center auto" id="profile-img" onClick={this.changeImage.bind(this)}>
                                            <ImageLoader
                                                src={URL_API + "users/image/?idImage=" + this.state.idImage}
                                                loading={() => <div className="big-spinner justify-center align-center column">
                                                    <div className="white-space-24"></div>
                                                    <i className="fas fa-spinner fa-spin" />
                                                    <div className="white-space-24"></div>
                                                </div>}
                                                onError={this.imgError.bind(this)}
                                                image={ props => <img src={props.src} alt="Teacher" id="img-profile" alt="Subir Imagen"/>}
                                            />
                                            {/*<img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading"/>*/}
                                        </div>
                                        <input type="file" name="profile-image" id="profile-image" className="hidden"
                                            onChange={this.handleChange.bind(this)} />
                                        <div className="white-space-16" />
                                        <div className="btn-container">
                                            <button type="submit" className="btn btn-orange">
                                                {this.props.update_loading ? (
                                                    <i className="fas fa-spinner fa-spin" />
                                                    ) : (
                                                    <i className="fas fa-check" />
                                                )}
                                                &nbsp; UPDATE PROFILE
                                            </button>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <p>
                                            Or
                                        </p>
                                        <div className="btn-container">
                                            <button className="btn color-orange" type="button" onClick={() => {
                                                document.getElementById('modal_users_password').classList.remove('close-modal');
                                                document.getElementById('modal_users_password').classList.remove('hidden');
                                                }}>
                                                <i className="fas fa-key"></i> UPDATE PASSWORD
                                            </button>
                                        </div>
                                    </div>
                                </form>                                
                            </div>
                        </div>
                    </div>
                    <div className="white-space-32"></div>
                </div>
                <Modal user={this.props.user.email}/>
            </div>
        );
    }

    componentDidMount() {
        document.getElementById('profile-img').style.cursor = 'pointer';
    }

    handleUpdate(event) {
        event.preventDefault();
        let data = {
            email: this.state.email,
            skype: this.state.skype,
            name: this.state.name,
            utc: this.state.timezone,
            idImage: this.state.idImage,
			image: this.state.imagen,
			nationality: this.state.nationality,
			languages: this.state.languages,
			experience: this.state.experience,
			background: this.state.background,
			specialist: this.state.specialist,
			telephone: this.state.telephone
        };

        this.updateUserMethod(data);
    }

    async updateUserMethod(data) {
        const updateValue = await updateUser(data);
        if (updateValue.message) {
            this.setState({ message: updateValue.message });
            if (updateValue.updated) {
                this.setState({ message: 'Updated user' });
                window.location.reload();
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
        utc: state.utc,
        utc_loading: state.utc_loading,
				update_loading: state.updateuser_loading,
				nationalities: state.nationalities,
				nationalities_loading: state.nationalities_loading
    };
}

export default connect(mapStateToProps)(Profile);
