import api from "superagent";
import store from "./store";

export const request = async function(url, method, types, data, token) {
  const prom = new Promise((res, rej) => {
    res(apiRequest(url, method, types, data, token));
  });

  const response = await prom.then(res => {
    return res;
  });

  return response;
};

async function apiRequest(url, method, types, data, token) {
  const [REQUEST, SUCCESS, ERROR] = types;
  store.dispatch({ type: REQUEST });
  let response = {};
  switch (method) {
    case "GET":
      response = await api
        .get(url)
        .set("auth", token? token : undefined)
        .query(data)
        .then(res => {
          store.dispatch({
            type: SUCCESS,
            data: res.body
          });
          return res.body;
        })
        .catch(err => {
          store.dispatch({
            type: ERROR,
            error: { error: true, message: err.message }
          });
          return { error: true, message: err.message };
        });
      return response;

    case "POST":
      response = await api
        .post(url)
        .set("auth", token? token : undefined)
        .send(data)
        .then(res => {
          store.dispatch({
            type: SUCCESS,
            data: res.body
          });
          return res.body;
        })
        .catch(err => {
          store.dispatch({
            type: ERROR,
            error: { error: true, message: err.message }
          });
          return { error: true, message: err.message };
        });
      return response;

      default:
      break;
  }
}
