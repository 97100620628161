import React, { Component } from 'react';
import { connect } from 'react-redux';

// Web Components
import Aside from '../../components/user/aside';

// Images
import Images from '../../img/index';

class Help extends Component {

    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }
    }

    handleChange(event) {
        this.setState({
            message: event.target.value
        })
    }

    handleClick() {
        document.getElementById('send-feedback').click();
    }

    render() {        
        return (
            <div className="user-admin justify-center">
                <div className="container column">
                    <div className="white-space-32"></div>
                    <div className="row-responsive break-row">
                        <Aside current = {"active-help"} />
                        {/*  Load View Here */}
                        <div className="user-area">
                            <div className="help-page column">
                                <h2 className="color-orange">
                                    HELP CENTER
                                </h2>
                                <div className="white-space-32"></div>
                                <div className="help-intro row-responsive">
                                    <div className="left auto">
                                        <img src={Images['testimonial_5']} alt="Ready?" title="Ready?" />
                                    </div>
                                    <div className="rigth column">
                                        <h3 className="color-orange align-center">
                                            <i className="fas fa-question-circle"></i>&nbsp; How to use this tool?
                                        </h3>
                                        <div className="white-space-16"></div>
                                        <p className="text-justify">
                                            <b className="color-lightGreen"> My Profile: </b> to edit your personal data
                                        </p>
                                        <div className="white-space-8"></div>
                                        <p className="text-justify">
                                            <b className="color-lightGreen"> Schedule a Session: </b> to book a Session to practise
                                        </p>
                                        <div className="white-space-8"></div>
                                        <p className="text-justify">
                                            <b className="color-lightGreen"> My Sessions: </b> to learn about your historical data
                                        </p>
                                        <div className="white-space-8"></div>
                                        <p className="text-justify">
                                            <b className="color-lightGreen"> Buy Credit: </b> to buy and be able to book a session
                                        </p>
                                        <div className="white-space-8"></div>
                                        <p className="text-justify">
                                            <b className="color-lightGreen"> Teachers: </b> to have a look at our teachers' profile
                                        </p>
                                        <div className="white-space-16"></div>
                                        <p className="text-justify">
                                            Have a look at the User's manual to learn how to use this tool. <a className="color-lightGreen underline" target="_newtab" href="https://beceonline.com/static/media/BECE_Users_manual.pdf" download>Download</a>
                                        </p>
                                        <div className="white-space-16"></div>
                                        <p className="text-justify">
                                            If you wish to contact BECEONLINE, email us at <a className="color-lightGreen underline" href="mailto:hello@bece.cat">hello@bece.cat</a>
                                        </p>
                                    </div>
                                </div>
                                <div className="suggest column">
                                    <div className="white-space-32"></div>
                                    <h3 className="color-orange align-center">
                                        We would like to have your feedback please
                                    </h3>
                                    <div className="white-space-8"></div>
                                    <p className="text-justify">
                                        Tell us about your experience, any suggestion will be welcomed to improve BECEONLINE, please be part of it – Thank you.
                                    </p>
                                    <div className="white-space-16"></div>
                                    <textarea name="suggest" rows="4" className="input textarea" maxLength="800" onChange={this.handleChange.bind(this)} value={this.state.value}></textarea>
                                    <div className="white-space-16"></div>
                                    <div className="btn-container">
                                    <button className="btn btn-orange" onClick={this.handleClick.bind(this)}>
                                        <i className="fas fa-check"></i> SEND
                                    </button>
                                        <a id="send-feedback" className="hidden" href={`mailto:beth@bece.cat?subject=Feedback from: ${this.props.user.email}&body=${this.state.message}`}></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="white-space-32"></div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Help);