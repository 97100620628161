import React, { Component } from 'react';
import moment from 'moment-timezone';

class Row extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        
        return(
            <tr onClick = {this.appearModal.bind(this, this.props.session)}>
                <td>{moment(this.props.session.CLASS_DATETIME).tz(this.props.user.utc).format('YYYY-MM-DD')}</td>
                <td>{moment(this.props.session.CLASS_DATETIME).tz(this.props.user.utc).format('HH:mm')}</td>
                <td>{this.props.session.DURATION}&nbsp;min</td>
                <td>{this.props.session.TEACHER}</td>
                <td>{this.props.session.STATUS}</td>
            </tr>
        );
    }

    appearModal(session) {
        this.props.setSession(session);
        document.getElementById("modal-feedback").classList.remove("close-modal");
        document.getElementById("modal-feedback").classList.remove("hidden");
    }

};

export default Row;