import React, { Component } from "react";
import moment from "moment-timezone";
import { updateCommentsStudent } from "../../../redux/actions";
import { connect } from 'react-redux';
const skypeColor = { color:  '#0078d7'};

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {            
            commentUser: this.props.session.COMMENTS,
            commentTeacher: this.props.session.TEACHER_COMMENTS,
            admin: this.props.admin,
            teacher: this.props.teacher,
			status: this.props.session.IDSTATE,
			cancelReason: this.props.session.CANCEL_REASON || ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
		if (this.props.openModal !== prevProps.openModal) {
			const select = document.getElementById("select-status");
			if (select) {
				select.value = this.props.session.IDSTATE || '1';
			}
			this.setState({
				status: this.props.session.IDSTATE,
				cancelReason: this.props.session.CANCEL_REASON || ''
			});
		}
        if (this.props.session !== prevProps.session) {
			console.log(this.props.session)
            this.setState({
                commentTeacher: this.props.session.TEACHER_COMMENTS, 
                commentUser: this.props.session.COMMENTS,
				status: this.props.session.IDSTATE,
				cancelReason: this.props.session.CANCEL_REASON,
            });
		}
    }

    handleTeacher(event) {
        this.setState({ commentTeacher: event.target.value });
    }

    handleUser(event) {
        this.setState({ commentUser: event.target.value });
    }

    handleUpdate() {
        if(this.state.status === '2') {
            if(moment( this.props.session.CLASS_DATETIME ).diff(moment(), 'minutes') < 240) {
                alert('ONLY CAN CANCEL UNTIL 4Hrs BEFORE SESSION');
                return;
            }
        }
        let data = {
            idSchedule: this.props.session.IDSCHEDULE,
            commentsUser: this.state.commentUser,
            commentsTeacher: this.state.commentTeacher,
			status: this.state.status,
			user: this.props.user,
			cancelReason: this.state.cancelReason
        }
        this.updateCommentsMethod(data);
    }

    async updateCommentsMethod(data) {
        this.setState({message: '', loading: true});
        const updateValue = await updateCommentsStudent(data);
        if (updateValue.message) {
            this.setState({ message: updateValue.message });
            if (updateValue.data) {
                window.location.reload();
            }
        }
        this.setState({loading: false});
    }

    render() {
		// console.log(this.props)
        return (
            <div className="flex modal justify-center hidden" id="modal-feedback">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            SEND FEEDBACK
                        </h2>
                        <div className="close-x justify-end">
                            <h3 className="color-white" onClick={this.closeModal.bind(this)}>x</h3>
                        </div>
                    </div>
                    <div className="modal-body column justify-center align-center">
                        <div className="white-space-32"></div>
                        <div className="container">
                            <div className="confirmation-data column">
                                <div className="flex row justify-between">
                                    <div className="column">
                                        <p className="text-mini">
                                            Teacher: <b> {this.props.session.TEACHER} </b>
                                        </p>
                                        <div className="white-space-8"></div>
                                        <p className="text-mini">
                                            Start Time:&nbsp;
											<b>
                                                {
                                                    `${moment(this.props.session.CLASS_DATETIME).tz(this.props.timezone.utc).format("YYYY-MM-DD")} 
													${moment(this.props.session.CLASS_DATETIME).tz(this.props.timezone.utc).format("HH:mm a")}`
                                                }
                                            </b>
                                        </p>
                                    </div>
                                    <div className="column">
										{this.props.session.STUDENT_URL? 
										<p className="text-mini row">
											<a href={`${this.props.session.STUDENT_URL}`} target="_newtab" className="zoom_button">
												JOIN ZOOM
											</a>
										</p>
										:
										<p className="text-mini">
											Teacher Skype:&nbsp;
											<a href={`skype:${this.props.session.TEACHER_SKYPE}?call`}>
												<i className="fab fa-2x fa-skype color-orange" style={skypeColor}></i>
												<b> {this.props.session.TEACHER_SKYPE} </b>
											</a>
										</p>
										}
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="request justify-center">
                                    <textarea name="request" rows="8" disabled={true} className="input textarea" maxLength="800" placeholder="User comments" value={this.state.commentUser} onChange={this.handleUser.bind(this)}>
                                    </textarea>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="request justify-center">
                                    <textarea name="request" rows="8" disabled={!this.state.teacher || !this.state.admin} className="input textarea" maxLength="800" placeholder="Teacher comments" value={this.state.commentTeacher} onChange={this.handleTeacher.bind(this)}>
                                    </textarea>
                                </div>
                                <div className="white-space-16"></div>
                                <div className="row align-center">
                                    <div className="row align-center">
                                        <p>
                                            Status: &nbsp;
                                    </p>
                                        <select name="status" id="select-status" className="input input-select" value={this.state.status} disabled={this.props.session.IDSTATE === 1 ? false : true}
                                            onChange={(event) => {
                                                if (this.props.session.IDSTATE === 1) {
                                                    this.setState({ status: event.target.value })
                                                }
                                            }}>
                                            {this.props.session.IDSTATE === 2 ?
                                                <option value="2">CANCELLED</option>
                                                :
                                                <React.Fragment>
                                                    <option value="1">OPEN</option>
                                                    <option value="2">CANCEL</option>
                                                </React.Fragment>
                                            }
                                            {this.props.session.IDSTATE === 3 &&
                                                <option value="3">FINISHED</option>
                                            }
                                            {this.props.session.IDSTATE === 4 &&
                                                <option value="4">FAILED</option>
                                            }
                                            {this.props.session.IDSTATE === 5 &&
                                                <option value="5">CANCELLED BY TEACHER</option>
                                            }
                                            {this.props.session.IDSTATE === 6 &&
                                                <option value="6">NO FEEDBACK</option>
                                            }
                                        </select>
                                    </div>
                                    <div className="row full justify-start align-center">
                                        {this.state.status == 2 &&
                                            <input style={{ width: '90%' }} className="input input-text" placeholder="please, write the reason of your cancellation" disabled={this.props.session.IDSTATE === 2}
                                                value={this.state.cancelReason}
                                                onChange={(event) => this.setState({ cancelReason: event.target.value })} required></input>
                                        }
                                    </div>
                                </div>
                                <div className="white-space-8"></div>
                                <p>
                                    {this.state.message}
                                </p>
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                        <div className="controls-group">
                            <div className="accept">
                            {this.state.loading ? 
                                (
                                    <button className="btn btn-orange">
                                        <i className="fas fa-sync fa-spin" />
                                    </button>
                                )
                                : (
                                <button className={`btn ${this.props.session.IDSTATE === 1 ? 'btn-orange' : 'btn-disabled' }`} onClick = {this.handleUpdate.bind(this)} disabled = { this.props.session.IDSTATE === 1 ? false : true } >
                                    <i className="fas fa-check-double" />
                                    &nbsp; SEND
                                </button>
                                )
                            }
                            </div>
                            <div className="cancel">
                                <button className="btn btn-red" data-close="book" onClick={this.closeModal.bind(this)} >
                                    <i className="fas fa-times" />
                                    &nbsp; CLOSE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    closeModal() {
        document.getElementById("modal-feedback").classList.add("close-modal");
        setTimeout(() => {
            document.getElementById("modal-feedback").classList.add("hidden");
		}, 300);
		if (this.props.onClose) {
			this.props.onClose();
		}
    }
}

function mapStateToProps(state) {
    return {
        timezone: state.user,
        teacher: state.user.teacher,
		admin: state.user.admin,
		user: state.user
    }
}

export default connect(mapStateToProps)(Modal);
