import React from 'react';
import { getTeachers } from "../../redux/actions";
import { URL_API } from "../../redux/urls";
import ImageLoader from "react-loading-image";

// Images
import Images from '../../img/index';

// Web Components
import Aside from '../../components/user/aside';
import Paginator from '../../components/paginator';

class TechersPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teachers: [],
            loading: null,
            page: 1
        }

        this.getTeachers();
    }

    imgError(event) {
        event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png";
    }

    async getTeachers() {
        const teachers = await getTeachers();
        this.setState({
            loading: false
        })
        if (teachers) {
            this.setState({ teachers: teachers.teachers, loading: true });
        }
    }

    setpage(page) {
        this.setState({ page: page });
    }

    render() {
        let teachers = [], teachers_length = 0;
        if (this.state.teachers) {
            teachers = this.state.teachers;
            teachers_length = teachers.length;
            teachers = this.state.teachers.slice(((this.state.page - 1) * 4), (this.state.page * 4));;
        }

        return(
            <div className="user-admin justify-center">
                <div className="container column">
                    <div className="white-space-32"></div>
                    <div className="row-responsive break-row">
                        <Aside current={"active-teachers"} />
                        {/*  Load Views Here */}
                        <div className="user-area">
                            <div className="teachers-page column">
                                <h2 className="color-orange" id="to-teachers">
                                    TEACHERS
                                </h2>
                                <div className="white-space-16"></div>
                                <h3 className="color-orange">
                                    Our certificates
                                </h3>
                                <div className="white-space-32"></div>
                                <div className="row-responsive responsive-img">
                                    <img src={Images['cambridge4']} alt="Cambridge Certificate" title="Cambridge Certificate" />
                                    <img src={Images['cambridge2']} alt="Cambridge Certificate" title="Cambridge Certificate" />
                                    <img src={Images['cambridge3']} alt="Cambridge Certificate" title="Cambridge Certificate" />
                                </div>
                                <div className="white-space-32"></div>
                                <h3 className="color-orange">
                                    Do you want to know about our teachers?
                                </h3>
                                <div className="white-space-32"></div>
                                <div className="teachers column">
                                    <div className="teachers-list column">
                                        {
                                            this.state.loading ? 
                                            <React.Fragment>
                                                {teachers.map((teacher) => (
                                                    <div className="teachers-list-item row-responsive">
                                                        <div className="teacher-picture auto">
                                                            <ImageLoader src={URL_API + "users/image/?idImage=" + teacher.IDIMAGE}
                                                                image={props => <img src={props.src} alt="Teacher Profile" />}
                                                                loading={() => 
                                                                <div className="medium-spinner justify-center align-center">
                                                                    <i className="fas fa-spinner fa-spin" />
                                                                </div>}
                                                                onError={this.imgError.bind(this)} />
                                                            {/*<img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading" />*/}
                                                        </div>
                                                        <div className="teacher-data column justify-center">
                                                            <p className="color-orange weight-bold">
                                                                {teacher.NAME}
                                                            </p>
                                                            <div className="white-space-8"></div>
                                                            <p className="text-justify">
                                                                <b>Nationality: </b>
                                                                {teacher.NATIONALITY} <br />
                                                                <b>Languages: </b>
                                                                {teacher.LANGUAJES} <br />
                                                                <b>Background: </b>
                                                                {teacher.BACKGROUND} <br />
                                                                <b>Teaching experience: </b>
                                                                {teacher.EXPERIENCE}. <br />
                                                                <b>Specialist subjects: </b>
                                                                {teacher.SPECIALIST} <br />
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="white-space-16"></div>
                                                {teachers_length > 4 ? <Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(teachers_length / 4)} /> : null}
                                            </React.Fragment>
                                            : (
                                            <div className="justify-center">
                                                <i className="fas fa-spin fa-spinner"></i>
                                            </div> 
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="white-space-32"></div>
                </div>
            </div>
        );
    }
}

export default TechersPage;