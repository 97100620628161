import React, { Component } from "react";
import moment from 'moment-timezone';
import { connect } from 'react-redux';

const Row = (props) => {
	return (
		<tr>
			<td>{props.USERNAME}</td>
			<td>
				{moment(props.CREDIT_DATE).tz(props.user.utc).format('YYYY-MM-DD HH:mm a')}
			</td>
			<td>{props.CREDIT_TYPE}</td>
			<td>{props.CREDIT_VALUE}</td>
			<td className="view">
				<button className="btn btn-orange" disabled={! (props.SCHEDULE > 0)} onClick={props.selectClass.bind(this, props.SCHEDULE)}>
					VIEW
				</button>
			</td>
		</tr>
	)
}

class CreditTable extends Component {
  constructor(props) {
    super(props);
    this.state={}
  }
  render() {
    let credits = [];
    if (this.props.credits) {
      credits = this.props.credits.slice(
        (this.props.page - 1) * 12,
        this.props.page * 12
      );
    }
    return (
      <div className="table-responsive">
        <table className="table-responsive table-credits">
          <thead>
            <tr>
              <th> User </th>
              <th> Date </th>
              <th> Action </th>
              <th> Quantity </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/*{
              this.props.loading ? (
                <tr>
                  <td colSpan="4">
                    <i className="fas fa-spinner fa-spin" />
                  </td>
                </tr>
              ) : null                        
            }*/}
            {credits.map((credit, index) => 
              <Row key={index} {...credit} user={this.props.user} selectClass={this.props.selectClass.bind(this)}/>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps)(CreditTable);
