import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// PDF
import Faqs from './../files/BC_Faqs.pdf';

// Images
import Images from '../img/index';

const Home = (props) => (
    <div className="column">
    <Helmet>
        <meta property="og:title" content="BéCé - Training in English" />
        <meta property="og:url" content={`http://www.beceonline.com${props.location.pathname}`} />
    </Helmet>
        <div className="introduction justify-center">
            <div className="container row-responsive">
                <div className="left">
                    <img src={Images['testimonial-hi1']} alt="Hi" title="Hi" />
                </div>
                <div className="right column justify-center">
                    <div className="white-space-32"></div>
                    <h1 className="color-orange">
                        Practise English online
                    </h1>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        ONE2ONE in live online sessions with 
                        <Link to="/teachers" className="color-orange underline link">
                            &nbsp;Certified English Teachers
                        </Link>
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                         BECEONLINE meets your needs & helps you achieve them
                    </p>
                    <div className="white-space-24"></div>
                    <div className="btn-container">
                        <Link to="/register" className="btn btn-orange">
                            <i className="fas fa-plus-circle"></i>&nbsp; CREATE AN ACCOUNT
                        </Link>
                    </div>
                    <div className="white-space-32"></div>
                </div>
            </div>
        </div>
        <div className="promotional-video justify-center">
            <div className="container column">
                <div className="white-space-32"></div>
                <h2 className="color-orange text-center">
                    How does it work?
                </h2>
                <div className="white-space-32"></div>
                <div className="video justify-center align-center">
                    <iframe src="https://www.youtube.com/embed/Y2sidbCBxl4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="pro-vid"></iframe>
                </div>
                <div className="white-space-32"></div>
            </div>
        </div>
        <div className="white-space-32"></div>

        <div className="introduction justify-center">
            <div className="container row-responsive">
                <div className="left">
                    <img src={Images['testimonial_2']} alt="Hello" title="Hello" />
                </div>
                <div className="right column justify-center">
                    <div className="white-space-32"></div>
                    <h2 className="color-orange">
                        Online Training Service,
                        What is it?
                    </h2>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        It is a ONE2ONE flexible way to interact with English teachers using Skype.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        We are experienced teachers, able to work with and help clients with different abilities and needs.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        Learn faster with our English tutor at your convenience.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        All you need is an internet connection, a Skype account and a computer, smart phone or tablet.
                    </p>
                    <div className="white-space-24"></div>
                    <div className="btn-container">
                        <a href = {Faqs} className="btn btn-orange">
                            <i className="fas fa-question-circle"></i>&nbsp; SEE THE FAQ's
                        </a>
                    </div>
                    <div className="white-space-32"></div>
                </div>
            </div>
        </div>
        <div className="introduction justify-center">
            <div className="container row-responsive">
                <div className="left">
                    <img src={Images['testimonial_4']} alt="Hello" title="Hello" />
                </div>
                <div className="right column justify-center">
                    <div className="white-space-32"></div>
                    <h2 className="color-orange">
                        Why?
                    </h2>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> To practise English online
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> To use your English & keep it updated
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> To beat your shyness
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> To improve English from different accents
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> A fun way to have English conversation sessions!
                    </p>
                    <div className="white-space-32"></div>
                </div>
            </div>
        </div>

        <div className="forthis justify-center">
            <div className="container column">
                <div className="white-space-32"></div>
                <h2 className="color-orange text-center">
                    We can also help you with: 
                </h2>
                <div className="white-space-32"></div>
                <div className="row-responsive gutters">
                    <div className="card column">
                        <div className="white-space-16"></div>
                        <div className="icon">
                            <img src={Images['school']} alt="University" title="University" />
                        </div>
                        <div className="white-space-16"></div>
                        <h4>
                            Prepare for university degrees
                        </h4>
                        <div className="white-space-16"></div>
                        <div className="line"></div>
                        <div className="white-space-16"></div>
                        <p className="text-justify">
                            First Certificate, Toefl, Advance, Bulats, iELTS, Proficiency
                        </p>
                        <div className="white-space-16"></div>
                    </div>
                    <div className="card column">
                        <div className="white-space-16"></div>
                        <div className="icon">
                            <img src={Images['professional']} alt="University" title="University" />
                        </div>
                            <div className="white-space-16"></div>
                            <h4>
                                Take it to the next level:
                            </h4>
                            <div className="white-space-16"></div>
                            <div className="line"></div>
                            <div className="white-space-16"></div>
                            <p className="text-justify">
                                Advance your career, just self-prepare beforehand & go for it,	BECEONLINE will help you!
                            </p>
                            <div className="white-space-16"></div>
                        </div>
                        <div className="card column">
                            <div className="white-space-16"></div>
                            <div className="icon">
                            <img src={Images['world']} alt="University" title="University" />
                        </div>
                        <div className="white-space-16"></div>
                        <h4>
                            BECE & Travelling
                        </h4>
                        <div className="white-space-16"></div>
                        <div className="line"></div>
                        <div className="white-space-16"></div>
                        <p className="text-justify">
                            Want to go ABROAD to study english? BéCé will also organize it for you, prepare your English to travel,
                        </p>
                        <div className="white-space-16"></div>
                    </div>
                </div>
                <div className="white-space-64"></div>
                <h2 className="color-orange text-center">
                    Successful stories – Clients feedback
                </h2>
                <div className="white-space-32"></div>
                <div className="testimonial column">
                    <p className="text-justify">
                        "I’m very enthusiastic at each class, which are different and relevant to our profession. Our communication skills have improved and are able to be more accurate with our clients – formal / informal English. We are looking forward to go on next course. "
                    </p>
                    <div className="white-space-16"></div>
                    <div className="photo align-center">
                        <b> - <i>Equip Can Jubany</i></b>
                    </div>
                    <div className="white-space-32"></div>
                </div>
                <div className="white-space-16"></div>
                <div className="testimonial column">
                    <p className="text-justify">
                        "Personally, BECE is the best way to learn a language. Weekly detailed prepared lessons make a difference and by having a demanding teacher, both have helped me achieve my professional and personal goals. BECE ONE2ONE is highly recommended!"
                    </p>
                    <div className="white-space-16"></div>
                    <div className="photo align-center">
                        <b> - <i>Gerard Autet</i></b>
                    </div>
                    <div className="white-space-32"></div>
                </div>
                <div className="white-space-32"></div>
            </div>
        </div>

        <div className="introduction justify-center">
            <div className="container row-responsive">
                <div className="left">
                    <img src={Images['testimonial_5']} alt="Hello" title="Hello" />
                </div>
                <div className="right column justify-center">
                    <div className="white-space-32"></div>
                    <h2 className="color-orange">
                        Benefits?
                    </h2>
                    <div className="white-space-16"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> Convenience – Learn from wherever you are – from home, office, hotel, airport lounge or anywhere you can get a good
                        internet connection.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> Flexibility – Choose your own schedule and manage any changes as needed (24 hrs in advance).
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> Efficiency – No travelling time to/from class and you choose the length of the sessions.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> Personalisation – The teacher can and will ONLY focus on your needs.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> Value – Excellent price for a high quality service - Experienced teachers!
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> Difference – In live on-line sessions & for Intermediate to proficiency speakers.
                    </p>
                    <div className="white-space-8"></div>
                    <p className="text-justify">
                        <i className="fas fa-check"></i> <b>Feedback – As a BECE client, you will receive the teacher's class feedback, from vocabulary,  pronunciation to links where to practise your grammar and writing, once the session are finished! </b>
                    </p>
                    <div className="white-space-24"></div>
                    <div className="btn-container">
                        <Link to="/register" className="btn btn-orange">
                            <i className="fas fa-plus-circle"></i>&nbsp; START LEARNING
                        </Link>
                    </div>
                    <div className="white-space-32"></div>
                </div>
            </div>
        </div>
        <div className="white-space-32"></div>
    </div>
);

export default Home;