import React from "react";
import moment from 'moment-timezone';

const BuyRow = props => {
  let currency = 0;
  if( props.CREDIT_TYPE) {
    //currency = props.CREDIT_TYPE.toFixed(2);
  }
  return (
    <tr>
      <td> {moment(props.CREDIT_DATE).tz('America/Cancun').format('YYYY-MM-DD hh:mm a')} </td>
      <td> 
      {
          props.CREDIT_TYPE ? (props.CREDIT_TYPE) : ('BUYED CREDIT')
      } </td>
      <td> {`${props.CREDIT_VALUE} min`} </td>
    </tr>
  );
};

export default BuyRow;
