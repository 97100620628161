
/** 
* Index.js
* Function to get all Images and parse it in a object
*/

const context = require.context('./', true, /.$/);
const obj = {};
context.keys().forEach((key) => {
    const countryCode = key.split('./').pop().substring(0, key.length - 6);
    obj[countryCode] = context(key);
});

export default obj;