import React, { Component } from "react";
import moment from "moment-timezone";
import { reserveSESSIONS } from "../../../redux/actions";
import { URL_API } from "../../../redux/urls";
import Request from '../../../core/httpClient';
// Images
import ImageLoader from "react-loading-image";

const req = new Request();

class Modal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false      
		};
	}

	async bookSession(session, comment) {
		this.setState({ loading: true });
		const { user, timezone } = this.props; 
		let data = { ...session, COMMENT: comment, USERNAME: user.email, USERTZ: timezone, STUDENT: user.name };
		//obtener links de sesion
		const zoom_session = await req.post(`/zoom/create/meeting`, data);
		if (zoom_session.start_url && zoom_session.join_url) {
			data = {...data, TEACHER_URL: zoom_session.start_url, STUDENT_URL: zoom_session.join_url};
			const reserve = await reserveSESSIONS(data);
			if (reserve.message) {
				this.setState({ message: reserve.message });
			}
			// console.log(reserve.session);
			if(reserve.session > 0) {
				window.location = ('/user/sessions');
			}
		} else {
			alert('The session could not be created with zoom, please contact with admin.');
		}
		this.setState({ loading: false });
	}

	imgError(event) {
		event.target.src =
		"https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png";
	}

	render() {
		let session = {};
		if (this.props.session) {
			session = this.props.session;
		}

    	return (
			<div className="flex modal justify-center hidden" id="modal-book">
				<div className="container modal-content column">
				<div className="modal-header justify-center">
					<h2 className="color-white">
					CONFIRM SESSION
					</h2>
					<div className="close-x justify-end">
					<h3 className="color-white" onClick={this.closeModal.bind(this)}>x</h3>
					</div>
				</div>
				<div className="modal-body column align-center">
					<div className="white-space-16" />
					<div className="container">
					<div className="confirmation-data column">
						<div className="teacher-picture auto">
						<ImageLoader
							src={URL_API + "users/image/?idImage=" + session.IDIMAGE}
							image={props => <img src={props.src} alt="Teacher" />}
							loading={() => <img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading" />}
							onError={this.imgError.bind(this)} id="profile-img" />
						</div>
						<div className="white-space-16" />
						<h3 className="color-orange weight-bold text-center">
						{session.TEACHER}
						</h3>
						<div className="white-space-16" />
						<div className="justify-center">
						<p className="text-center">
							Day: &nbsp;
							<span className="color-orange weight-medium">
							{moment(session.DIA).tz(this.props.timezone).format("YYYY-MM-DD")}
							</span>
						</p>
						</div>
						<div className="white-space-16" />
						<div className="row-responsive justify-center">
						<p className="text-center">
							Start Time: &nbsp;
						<span className="color-orange weight-medium">
							{moment(session.INI).tz(this.props.timezone).format("HH:mm a")}
							</span>
						</p>
						<p className="text-center">
							Finish Time: &nbsp;
						<span className="color-orange weight-medium">
							{moment(session.FIN).tz(this.props.timezone).format("HH:mm a")}
							</span>
						</p>
						</div>
						<div className="white-space-24" />
						<div className="request justify-center">
						<textarea
							name="request"
							rows="5"
							className="input textarea"
							maxLength="400"
							placeholder="Write any specific English request of your liking"
							value={this.state.comment}
							onChange={event =>
							this.setState({ comment: event.target.value })
							}
						/>
						</div>
						<div className="white-space-24" />
						<div className="request justify-center">
						<label>{this.state.message}</label>
						</div>
						<div className="white-space-24" />
					</div>
					</div>
					<div className="controls-group">
					<div className="accept">
					{this.state.loading ? 
						(
							<button className="btn btn-orange">
								<i className="fas fa-sync fa-spin" />
							</button>
						):
						(
						<button className="btn btn-orange" onClick={this.bookSession.bind(this, session, this.state.comment)}>
						<i className="fas fa-check-double" />
						&nbsp; CONFIRM &nbsp;
						</button>
						)
					}
					</div>
					<div className="cancel">
						<button
						className="btn btn-red"
						data-close="book"
						onClick={this.closeModal.bind(this)}
						>
						<i className="fas fa-times" />
						&nbsp; CANCEL
						</button>
					</div>
					</div>
				</div>
				</div>
			</div>
		);
  	}

	closeModal() {
		document.getElementById("modal-book").classList.add("close-modal");
		setTimeout(() => {
			document.getElementById("modal-book").classList.add("hidden");
		}, 300);
	}
}

export default Modal;
