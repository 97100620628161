import React, { Component } from "react";
import { newCalendar, delCalendar } from "../../../redux/actions";

// Web Components
// import CalendarRow from "./calendar-row";

class CalendarTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      loading: false
    };
  }

  async addCalendar(event) {
    event.preventDefault();
    this.setState({ loading: true });
    const form = event.target;
    const teacher = document.getElementById("teacher").value;
    if (teacher) {
      const data = {
        name: form.calendar_name.value,
        teacher: teacher
      };
      const calendars = await newCalendar(data);
      this.setState({ loading: false });
      if (calendars.message) {
        this.setState({ message: calendars.message });
      }
      if (calendars.calendars) {
        if (calendars.calendars.length > 0) {
          window.location.reload();
        }
      }
    }
  }

  async delCalendar(event) {
    event.preventDefault();
    this.setState({ loading: true });
    // const form = event.target;
    const teacher = document.getElementById("teacher").value;
    const calendar = document.getElementById("calendar").value;
    if (teacher) {
      const data = {
        name: calendar,
        teacher: teacher
      };
      const calendars = await delCalendar(data);
      this.setState({ loading: false });
      if (calendars.message) {
        this.setState({ message: calendars.message });
      }
      if (calendars.data > 0) {
        window.location.reload();
      }
    }
  }

  closeModal(modal) {
    this.setState({ message: "" });
    document.getElementById(modal).classList.add("close-modal");
    setTimeout(() => {
      document.getElementById(modal).classList.add("hidden");
    }, 200);
  }

  change(calendars, event) {
    let active = calendars.find(
      calendar =>
        parseFloat(calendar.IDCLASSCHEDULE) === parseFloat(event.target.value)
    );
    if (active) {
      active = active.ACTIVE;
    }
    this.props.setCalendar(
      event.target.options[event.target.selectedIndex].innerHTML,
      event.target.value,
      active
    );
    this.setState({
      calendar: event.target.options[event.target.selectedIndex].innerHTML
    });

    this.props.loadHours(event.target.value);
  }

  renderNewModal() {
    return (
      <div className="flex modal justify-center hidden" id="modal_new_calendar">
        <div className="container modal-content column">
          <div className="modal-header justify-center">
            <h2 className="color-white">ADD CALENDAR</h2>
            <div className="close-x justify-end">
              <h3 className="color-white" onClick={this.closeModal.bind(this, "modal_new_calendar")}>x</h3>
            </div>
          </div>
          <form
            className="modal-body column justify-center align-center"
            onSubmit={this.addCalendar.bind(this)}
          >
            <div className="container">
              <div className="column">
                <div className="white-space-32" />
                <div className="request justify-center align-center">
                  <label htmlFor="calendar_name">Calendar's Name</label>
                  <input
                    name="calendar_name"
                    id="calendar_name"
                    className="input input-text"
                    maxLength="256"
                    placeholder="Calendar's Name"
                  />
                </div>
                <div className="justify-center">
                  <label htmlFor="calendar_name">{this.state.message}</label>
                </div>
                <div className="white-space-32" />
              </div>
            </div>
            <div className="controls-group">
              <div className="accept">
                <button className="btn btn-orange" type="submit">
                  <i className="fas fa-check-double" />
                  &nbsp; CONFIRM &nbsp;
                  {this.state.loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                </button>
              </div>
              <div className="cancel">
                <button
                  className="btn btn-red"
                  data-close="book"
                  type="button"
                  onClick={this.closeModal.bind(this, "modal_new_calendar")}
                >
                  <i className="fas fa-times" />
                  &nbsp; CLOSE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderDelModal() {
    let calendar = "";
    if (this.state.calendar) {
      calendar = this.state.calendar;
    }
    return (
      <div
        className="flex modal justify-center hidden"
        id="modal_delete_calendar"
      >
        <div className="container modal-content column">
          <div className="modal-header justify-center">
            <h2 className="color-white">DELETE CALENDAR {calendar}</h2>
          </div>
          <form
            className="modal-body column"
            onSubmit={this.delCalendar.bind(this)}
          >
            <div className="white-space-24" />
            <div className="justify-center">
              <label>Do you really want to delete the calendar?</label>
            </div>
            <div className="white-space-16" />
            <div className="justify-center">
              <label htmlFor="calendar_name">{this.state.message}</label>
            </div>
            <div className="white-space-24" />
            <div className="controls-group">
              <div className="accept">
                <button className="btn btn-orange" type="submit">
                  <i className="fas fa-check-double" />
                  &nbsp; CONFIRM &nbsp;
                  {this.state.loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                </button>
              </div>
              <div className="cancel">
                <button
                  className="btn btn-red"
                  data-close="book"
                  type="button"
                  onClick={this.closeModal.bind(this, "modal_delete_calendar")}
                >
                  <i className="fas fa-times" />
                  &nbsp; CLOSE
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  render() {
    let calendars = [];
    if (this.props.calendars) {
      calendars = this.props.calendars;
    }
    return (
      <div className="filter-container input-group align-center">
        <label htmlFor="calendar">Calendar:</label>
        <select
          name="calendar"
          id="calendar"
          className="input input-select"
          onChange={this.change.bind(this, calendars)}
        >
          <option value="0">SELECT CALENDAR</option>
          {calendars.map((calendar, index) => (
            <option key={index} value={calendar.IDCLASSCHEDULE}>
              {calendar.NAME}
            </option>
          ))}
        </select>
        {this.renderNewModal()}
        {this.renderDelModal()}
      </div>
    );
  }
}

export default CalendarTable;
