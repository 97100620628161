import React, { Component } from "react";

// Web Components
import BuyRow from "../tables/buy-row";

class BuyTable extends Component {
  constructor(props) {
    super(props);
    this.state={}
  }
  render() {
    let credits = [];
    if ( this.props.credits ) {
      credits = this.props.credits.slice(((this.props.page - 1) * 12), (this.props.page * 12));
    }
    return (
      <table className="table-responsive table-credits">
        <thead>
          <tr>
            <th> Date </th>
            <th> Action </th>
            <th> Quantity </th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.loading ? (
              <tr>
                <td colSpan="3">
                  <i className="fas fa-spinner fa-spin" />
                </td>
              </tr>
            ) : null                        
          }
          {credits.map((credit, index) => 
            <BuyRow key={index} {...credit}/>
          )}
        </tbody>
      </table>
    );
  }
}

export default BuyTable;
