import React, { Component } from "react";
import { connect } from "react-redux";
import { getTeachers } from "../../redux/actions";
import ReactExport from "react-data-export";
import moment from 'moment-timezone';

// Web Components
import Aside from "../../components/user/aside";
import SessionsTable from "../../components/admin/tables/sessions-table";
import ActivityFilter from "../../components/admin/sessions/activity-filter";
import Modal from "../../components/admin/sessions/modal";
import Paginator from "../../components/paginator";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AdminSessions extends Component {
	constructor(props) {
		super(props);
		this.state = {
		clase: {},
			page: 1
		};
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const teachers = await getTeachers();
		if (teachers.teachers) {
			this.setState({ teachers: teachers.teachers });
		}
	}

	setpage(page) {
		this.setState({ page: page });
	}

	getClasses(classes) {
		console.log(classes);
		this.setState({ classes: classes });
	}

	renderExport() {
		let data = [];
		if (this.state.classes) {
			data = this.state.classes;
			data = data.map(session => {
				return session;
			});
			// console.log(data);
		}
		return (
			<ExcelFile
				element = {
				<button className="btn btn-orange">
					EXPORT TO EXCEL &nbsp; <i className="fas fa-file-excel" />
				</button>
				}>
				<ExcelSheet filename={'Sessions'} data={data} name="SESSIONS">
				<ExcelColumn label="Start Time" value="CLASS_DATETIME" />
				<ExcelColumn label="Duration" value="DURATION" />
				<ExcelColumn label="Status" value="STATE" />
				<ExcelColumn label="Student" value="STUDENT" />
				<ExcelColumn label="Teacher" value="TEACHER" />
				{/*<ExcelColumn label="Quantity" value="QUANTITY" />*/}
				</ExcelSheet>
			</ExcelFile>
		);
	}

	selectClase(clase) {
		this.setState({clase: clase, openModal: true});
	}

	render() {
		let teachers = this.state.teachers ? this.state.teachers : [];
		let classes = [];

		if (this.state.classes) {
			classes = this.state.classes;
		}

		return (
			<div className="user-admin justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<div className="row-responsive break-row">
						<Aside current={"active-sessions"} />
						{/*  Load Views Here */}
						<div className="admin-area">
							<div className="sessions-page column">
								<h2 className="color-orange" id="to-sessions">SESSIONS</h2>
								<div className="white-space-32" />
								<ActivityFilter
									teachers={teachers}
									getClasses={this.getClasses.bind(this)}
								/>
								<div className="white-space-16" />
								<p className="swipe-to-left">
									Swipe to the right to see more
								</p>
								<div className="white-space-16" />
								<div className="table-responsive">
									<SessionsTable
										message={this.state.message}
										classes={classes}
										utc={this.props.user.utc}
										selectClase={this.selectClase.bind(this)}
										page={this.state.page}
									/>
								</div>       
								<div className="white-space-16" />  
								{classes.length > 12 &&
									<Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(classes.length / 12)} />}      
								<div className="white-space-16" />
								<div>
									{this.renderExport()}
								</div>
								<div className="white-space-32" />
									<p className="text-justify">
										PLEASE <b>DO NOT FORGET</b> TO WRITE DOWN THE SESSION NOTES IN THE "TEACHER'S COMMENTS" BOX ONCE THE SESSION <b className="color-orange">IS COMPLETED.</b>
										THE CLIENT MUST RECEIVE THIS EMAIL AS FEEDBACK FOR FUTURE SESSIONS & INTERNAL TEACHERS INFORMATION - THANK YOU.
									</p>
								<div className="white-space-32" />
							</div>
						</div>
					</div>
					<div className="white-space-32" />
				</div>
				<Modal clase={this.state.clase} />
			</div>
		);
  	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps)(AdminSessions);
