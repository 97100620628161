import React, { Component } from "react";
import { connect } from "react-redux";

import { updateUser } from "../../redux/actions";
import { URL_API } from "../../redux/urls";

// Web Components
import Aside from "../../components/user/aside";
import Modal from "../../components/user/modals/changepass";
// Images
import ImageLoader from "react-loading-image";

class Profile extends Component {
  	constructor(props) {
    	super(props);
		this.state = {
			message: "",
			email: this.props.user.email,
			skype: this.props.user.skype,
			name: this.props.user.name,
			timezone: this.props.user.utc,
			idImage: this.props.user.idImage,
			userTopics: [],
			image: null,
			telephone: this.props.user.telephone
		};  
    	this.divideTopics = this.divideTopics.bind(this);
  	}

	changeTime(event) {
		console.log(event.target.value);
		this.setState({ timezone: event.target.value });
	}

	changeImage() {
		document.getElementById("profile-image").click();
	}

	handleChange(event) {
		let resizedImage = "";
		const src = event.target;
		let fr = new FileReader();
		fr.onload = function(e) {
			const image = new Image();
			image.onload = function(imageEvent) {
				// Resize the image
				const canvas = document.createElement("canvas");
				const max_size = 300;
				let width = image.width;
				let height = image.height;
				if (width > height) {
					if (width > max_size) {
						height *= max_size / width;
						width = max_size;
					}
				} else {
					if (height > max_size) {
						width *= max_size / height;
						height = max_size;
					}
				}

				canvas.width = width;
				canvas.height = height;

				canvas.getContext("2d").drawImage(image, 0, 0, width, height);
				resizedImage = canvas.toDataURL("image/png");
				document.getElementById("img-profile").src = resizedImage;
				this.setState({imagen: resizedImage});

			}.bind(this);

			image.src = e.target.result;

		}.bind(this);

		document.getElementById("profile-img").style.border = "none";

		if (src.files.length > 0) {
			fr.readAsDataURL(src.files[0]);
		}
	}

	imgError(event) {
		event.target.src = "https://via.placeholder.com/250x250?text=CLICK+TO+CHANGE";
	}

	handleUpdate(event) {
		event.preventDefault();
		const form = event.target;
		let topics = "";
		const checkboxes = form.business;
		for (var i = 0; i < checkboxes.length; i++) {
			if (checkboxes[i].checked) {
				topics = topics + checkboxes[i].value + "|";
			}
		}

		let data = {
			email: this.state.email,
			skype: this.state.skype,
			name: this.state.name,
			utc: this.state.timezone,
			idImage: this.state.idImage,
			image: this.state.imagen,
			topics: topics,
			telephone: this.state.telephone
		};
	
		this.updateUserMethod(data);
	}

	render() {
		console.log(this.props);
		let timezones = this.props.utc ? this.props.utc.data : [];
		let topics = this.props.topics ? this.props.topics.data : [];  
		
		return (
			<div className="user-admin justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<div className="row-responsive break-row">
						<Aside current={"active-profile"} />
						{/*  Load Views Here */}
						<div className="user-area">
						<div className="profile-page column">
							<h2 className="color-orange" id="to-profile">MY PROFILE</h2>
							<div className="white-space-32" />
							<h3 className="color-orange">Personal Info</h3>
							<div className="white-space-16" />
							<form className="row-responsive" onSubmit={this.handleUpdate.bind(this)} >
							<div className="left column">
								<div className="input-group align-center">
									<label htmlFor="username">Email:</label>
									<input type="email" name="email" disabled className="input input-text" value={this.state.email} onChange={event =>
										this.setState({ email: event.target.value }) } />
								</div>
								<div className="white-space-16" />
								<div className="input-group align-center">
									<label htmlFor="skype">Telephone:</label>
									<input
										type="phone"
										name="telephone"
										className="input input-text"
										value={this.state.telephone}
										onChange={event =>
											this.setState({ telephone: event.target.value })
										}
									/>
								</div>
								<div className="white-space-16" />
								<div className="input-group align-center">
									<label htmlFor="skype">Skype ID:</label>
									<input
										type="text"
										name="skype"
										className="input input-text"
										value={this.state.skype}
										onChange={event =>
										this.setState({ skype: event.target.value })
										}
									/>
								</div>
								<div className="white-space-16" />
								<div className="input-group align-center">
									<label htmlFor="fullname">Full Name:</label>
									<input
										type="text"
										name="name"
										className="input input-text"
										value={this.state.name}
										onChange={event =>
										this.setState({ name: event.target.value })
										}
									/>
								</div>
								<div className="white-space-16" />
								<div className="input-group align-center">
									<label htmlFor="timezone">Time Zone:</label>
									<select
										name="timezone"
										id="timezone"
										className="input input-select"
										value={this.state.timezone}
										onChange={this.changeTime.bind(this)}
									>
										{this.props.utc_loading ? (
										<option value="0">{"loading..."}</option>
										) : (
										timezones.map((tmz, index) => (
											<option value={tmz.zona} key={index}>
											{tmz.zona}
											</option>
										))
										)}
									</select>
								</div>
								<div className="white-space-32"></div>
								<h3 className="color-orange">
									Topics of Interest
								</h3>
								<div className="white-space-24"></div>
								<div className="input-group column">
									{this.props.topics_loading ? (
										<div className="align-center">
										<i className="fas fa-spinner fa-spin" />
										</div>
									) : (
										topics.map((topic, index) => (
											<span key = {index}>
											<div className="input-group align-center">
												<input type="checkbox" name="business" value={topic.IDTOPIC} id={topic.IDTOPIC} className="input-checkbox" />
												&nbsp; {topic.NAME}
											</div>
											<div className="white-space-8" />
											</span>
										))
										)}
								</div>

								<div className="white-space-16" />
								<div className="align-center justify-center">
									<label>{this.state.message}</label>
								</div>
							</div>
							<div className="right column justify-center align-center" id="profile-img-div" >
								<h4 className="color-orange">
											Profile Picture
								</h4>
								<div className="white-space-8"></div>
									<div className="justify-center auto" id="profile-img" onClick={this.changeImage.bind(this)}>
										<ImageLoader 
											src={ URL_API + "users/image/?idImage=" + this.state.idImage }
											image={props=> <img src={props.src} id="img-profile" alt="Subir Imagen"/>}
											loading={() => <div className="big-spinner justify-center align-center column">
											<div className="white-space-24"></div>
											<i className="fas fa-spinner fa-spin" />
											<div className="white-space-24"></div>
											</div>}
											onError={this.imgError.bind(this)}
										/>
										{/*<img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading"/>*/}
									</div>
									<input type="file" name="profile-image" id="profile-image" className="hidden" onChange={this.handleChange.bind(this)} />
									<div className="white-space-16" />
									<div className="btn-container">
										<button type="submit" className="btn btn-orange">
											{this.props.update_loading ? (
											<i className="fas fa-spinner fa-spin" />
											) : (
											<i className="fas fa-check" />
											)}
											&nbsp; UPDATE PROFILE
										</button>
									</div>
									<div className="white-space-8"></div>
									<p>
										Or
									</p>
									<div className="btn-container">
										<button className="btn color-orange" type="button" onClick={() => {
											document.getElementById('modal_users_password').classList.remove('close-modal');
											document.getElementById('modal_users_password').classList.remove('hidden');
										}}>
											<i className="fas fa-key"></i> UPDATE PASSWORD
										</button>
									</div>
									<div className="white-space-16"></div>
								</div>
							</form>
						</div>
					</div>
				</div>
				<div className="white-space-32"></div>
				</div>
				<Modal user={this.props.user.email}/>
			</div>
		);
	}

	async updateUserMethod(data) {
		const updateValue = await updateUser(data);
		if (updateValue.message) {
			this.setState({ message: updateValue.message });
			if (updateValue.updated) {
				this.setState({ message: "Updated user" });
				window.location.reload();
			}
		}
	}

	componentDidMount() {
		this.divideTopics(this.props.user.topics);
	}

	divideTopics(topics) {

		if( topics ) {

			let topicos = topics.split("|");

			topicos.map((topico) => {
				let activeTopic = document.getElementById(topico);

				if (activeTopic) {

				activeTopic.checked = true;

				}

			});

		}    

	}

}

function mapStateToProps(state) {
	return {
		user: state.user,
		utc: state.utc,
		utc_loading: state.utc_loading,
		update_loading: state.updateuser_loading,
		topics: state.topics,
		topics_loading: state.topics_loading
	};
}

export default connect(mapStateToProps)(Profile);
