import React, { Component } from "react";
import { saveCalendar } from "../../../redux/actions";
import moment from 'moment-timezone';
import TimeField from 'react-simple-timefield';

class DefinitionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.calendarSelected !== prevProps.calendarSelected) {
      this.setState({
        name: this.props.calendarSelected,
        active: this.props.active
      });
    }
    if (this.props.hours !== prevProps.hours) {
      let hours = this.state.hours;
      if(this.props.hours.length > 0) {
        hours = this.props.hours;
      }
      this.setState({
        hours
      });
    }
  }

  async saveCalendar() {
    let hours = this.state.hours.map(hour => {
      const oINIT1 = moment.tz(moment().format('YYYY-MM-DD') + ' ' + hour.INIT1, this.props.timezone);
      const oEND1 = moment.tz(moment().format('YYYY-MM-DD') + ' ' + hour.END1, this.props.timezone);
      const oINIT2 = moment.tz(moment().format('YYYY-MM-DD') + ' ' + hour.INIT2, this.props.timezone);
      const oEND2 = moment.tz(moment().format('YYYY-MM-DD') + ' ' + hour.END2, this.props.timezone);
      const oINIT3 = moment.tz(moment().format('YYYY-MM-DD') + ' ' + hour.INIT3, this.props.timezone);
      const oEND3 = moment.tz(moment().format('YYYY-MM-DD') + ' ' + hour.END3, this.props.timezone);
      console.log(this.props.timezone);
      return {
        ...hour,
        INIT1: oINIT1.clone().tz("GMT").format('HH:mm'),
        END1: oEND1.clone().tz("GMT").format('HH:mm'),
        INIT2: oINIT2.clone().tz("GMT").format('HH:mm'),
        END2: oEND2.clone().tz("GMT").format('HH:mm'),
        INIT3: oINIT3.clone().tz("GMT").format('HH:mm'),
        END3: oEND3.clone().tz("GMT").format('HH:mm')
      };
    })
    // console.log(hours);
    const data = {
      idSchedule: this.props.id,
      name: this.state.name,
      active: this.state.active,
      hours: hours
    };
    this.setState({ loading: true });
    const save = await saveCalendar(data);
    this.setState({ loading: false });
    if (save.message) {
      this.setState({ message: save.message });
    }
    if (save.data > 0) {
      window.location.reload();
    }
  }

  changeHour(idDay, key, event) {
    const hours = this.state.hours;
    Object.assign(hours.find(hour => hour.CLASS_DAY === idDay), {
      [key]: event
    });
    this.setState({ hours: hours });
  }

  renderTable() {
    const hours = this.state.hours;
    return (
      <div className="column">
        <div className="white-space-32" />
        <h3 className="color-orange">
          Calendar Definition
        </h3>
        <div className="white-space-8"></div>
        <p>
          <b>IMPORTANT: please, use 24h. format</b>
        </p>
        <div className="white-space-16" />
        <div className="row-responsive">
          <div className="input-group align-center">
            <p>
              Calendar's Name &nbsp; 
            </p>            
            <input type="text" className="input input-text" name="calendar_edit_name" id="calendar_edit_name" value={this.state.name} onChange={event => this.setState({ name: event.target.value })} />
          </div>
          <div className="input-group align-center" id="active-calendar">
            <p>
              Active Calendar &nbsp; 
            </p>            
            <input type="checkbox" className="input input-checkbox" name="calendar_edit_active" id="calendar_edit_active" checked={this.state.active || false}
              onChange={event =>
                this.setState({ active: event.target.checked })
              } />
          </div> 
          <div className="input-group align-center" id="p-timezone">
            <p>
              Time Zone: {`${this.props.timezone} ${moment.tz(this.props.timezone).format('z Z')}`} &nbsp; 
            </p>
          </div>
          {this.state.message ?          
          <div>
            <label>{this.state.message}</label>
          </div> : null}
        </div>
        <div className="white-space-16" />
        <div className="table-responsive">
          <table className="table-sessions">
            <thead>
              <tr>
                <th> Day </th>
                <th> BEGIN1 </th>
                <th> END1 </th>
                <th> BEGIN2 </th>
                <th> END2 </th>
                <th> BEGIN3 </th>
                <th> END3 </th>
                {/*<th>OPTION</th>*/}
              </tr>
            </thead>
            <tbody>
              {this.props.hours.map((day, index) => (
                <tr key={index}>
                  <td>{day.DAY_NAME}</td>
                  <td>
                    <div className="justify-center column">
                      {/*<time>00:00</time>*/}
                      <TimeField
                        value={day.INIT1 || "00:00"}
                        onChange={this.changeHour.bind( this, day.CLASS_DAY, "INIT1" )}
                        input={<input className="input input-text"></input>}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="justify-center column">
                      {/*<time>00:00</time>*/}
                      <TimeField
                        value={day.END1 || "00:00"}
                        onChange={this.changeHour.bind( this, day.CLASS_DAY, "END1" )}
                        input={<input className="input input-text"></input>}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="justify-center column">
                      {/*<time>00:00</time>*/}
                      <TimeField
                        value={day.INIT2 || "00:00"}
                        onChange={this.changeHour.bind( this, day.CLASS_DAY, "INIT2" )}
                        input={<input className="input input-text"></input>}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="justify-center column">
                      <TimeField
                        value={day.END2 || "00:00"}
                        onChange={this.changeHour.bind( this, day.CLASS_DAY, "END2" )}
                        input={<input className="input input-text"></input>}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="justify-center column">
                      <TimeField
                        value={day.INIT3 || "00:00"}
                        onChange={this.changeHour.bind( this, day.CLASS_DAY, "INIT3" )}
                        input={<input className="input input-text"></input>}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="justify-center column">
                      <TimeField
                        value={day.END3 || "00:00"}
                        onChange={this.changeHour.bind( this, day.CLASS_DAY, "END3" )}
                        input={<input className="input input-text"></input>}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>          
        </div>
        <div className="white-space-16"></div>
        <p className="text-center">
          IMPORTANT: please, use 24h. format
        </p>
        <div className="white-space-16"></div>
        <button className="btn btn-orange" onClick={this.saveCalendar.bind(this)} >
          SAVE CALENDAR &nbsp; <i className="fas fa-save" />
          {this.state.loading ? (
            <i className="fas fa-sync fa-spin" />
          ) : null}
        </button>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.props.id !== "0" && this.props.id !== "" && this.props.id
          ? this.renderTable()
          : null}
      </div>
    );
  }

  appearModal() {
    document.getElementById("modal-definition").classList.remove("close-modal");
    document.getElementById("modal-definition").classList.remove("hidden");
  }
}

export default DefinitionTable;
