import React, { Component } from 'react';
import { sendRecoverMail, validateToken, renderChangePassword } from '../redux/actions';

class Recover extends Component {

	constructor(props) {
		super(props);
		this.state={
			message: '',
			validToken: false
		}
	}

	componentDidMount() {
		if(this.props.match.params.id) {
			this.validateToken(this.props.match.params.id)
		}
	}

	async sendEmail(event) {
		event.preventDefault();
		this.setState({ message: '', loading: true });
		const data = {
			email: event.target.email.value
		}
		const send = await sendRecoverMail(data);
		// console.log(send)
		if( send.message ) {
			this.setState({ message: send.message })
		}
		this.setState({loading: false});
	}

	async validateToken(id) {
		const valid = await validateToken({token: id});
		console.log(valid);
		this.setState({validToken: valid.validToken, message: valid.message, email: valid.email || ''});
	}

	cipher(text) {
		const string64 = new Buffer(text).toString("base64");
		return string64;
	}

	async sendPassword(event) {
		event.preventDefault();
		this.setState({ message: '', loading: true });
		const form = event.target;
		if(form.newpassword.value !== form.repassword.value) {
			this.setState({message: 'The passwords not match'})
		} else {
			const data = {
				newpassword: this.cipher(form.newpassword.value),
				email: this.state.email
			}
			const change = await renderChangePassword(data);
			// console.log(change);
			if(change.message) {
				this.setState({message: change.message})
			}
			if(change.restore) {
				setTimeout(() => {
					window.location = "/login";
				}, 1500);
			}
		}
		this.setState({loading: false});
	}

	renderSendMail() {
		return (
			<form className="column justify-center align-center" onSubmit={this.sendEmail.bind(this)}>
				<label htmlFor="email">Provide your email to restore password</label>
				<div className="white-space-32" />
				<input name="email" id="email" className="input-text" required type="email"/>
				<div className="white-space-32" />
				<button className="btn btn-orange" type="submit">
					&nbsp; SEND &nbsp;
					{this.state.loading? <i className="fas fa-sync fa-spin"/> : null}
				</button>
				<div className="white-space-32" />
				<label>{this.state.message}</label>
			</form>
		)
	}

	renderChangePassword() {
		return (
			<div className="justify-center">
				{this.state.validToken?
				<form className="column justify-center align-center" onSubmit={this.sendPassword.bind(this)}>
					<label htmlFor="email">Provide your new password</label>
					<div className="white-space-32" />
					<input name="newpassword" id="newpassword" className="input-text" required type="password"/>
					<div className="white-space-32" />
					<label htmlFor="email">Retype password</label>
					<div className="white-space-32" />
					<input name="repassword" id="repassword" className="input-text" required type="password"/>
					<div className="white-space-32" />
					<button className="btn btn-orange" type="submit">
						&nbsp; SEND &nbsp;
						{this.state.loading? <i className="fas fa-sync fa-spin"/> : null}
					</button>
					<div className="white-space-32" />
					<label>{this.state.message}</label>
				</form>
				: <label>{this.state.message}</label>}
			</div>
		)
	}

	render() {
		return (
			<div className="column">
				<div className="white-space-32" />
					{this.props.match.params.id? this.renderChangePassword(): this.renderSendMail()}
				<div className="white-space-32" />
			</div>
		)
	}

}

export default Recover;