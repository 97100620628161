import { createStore } from "redux";
import reducer from "./reducer";

const loadState = function loadState() {
  try {
    let serializedState = localStorage.getItem("BECE");

    if (serializedState === null) {
      return undefined;
    }

    serializedState = JSON.parse(serializedState);
    return serializedState;
  } catch (error) {
    console.log("ERROR TYPE: STORE - " + error);
    return undefined;
  }
};

// Store all state
const persistedState = loadState();

const store = createStore(reducer, persistedState);

// Update the local storage

const saveState = function saveState(state) {
  try {
    let serializedState = JSON.stringify(state);
    localStorage.setItem("BECE", serializedState);
    localStorage.setItem("BECE_version", "1.0.0");
  } catch (error) {
    console.log("ERROR TYPE: SAVE STATE:  " + error);
  }
};

store.subscribe(() => {
  saveState(store.getState());
});

export default store;
