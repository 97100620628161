import React, { Component } from "react";
import { connect } from "react-redux";
import { login as actionLogin } from "../redux/actions";
import { Helmet } from 'react-helmet';

// Images
import Images from "../img/index";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: ""
    };

    if (this.props.auth) {
      window.location = "/user/help";
    }
  }

  cipher(text) {
    const string64 = new Buffer(text).toString("base64");
    return string64;
  }

  async login(event) {
    event.preventDefault();
    const form = event.target;
    const data = {
      username: form.username.value,
      password: this.cipher(form.password.value)
    };
    const response = await actionLogin(data);
    if (response) {
      this.setState({ message: response.message });
    }
    if (response.user) {
      if (response.user.auth && (response.user.teacher) || (response.user.admin)) {
        window.location = "admin/help";
      } else {
        if (response.user.auth) {
          window.location = "user/help";
        }
      }
    }
  }

  render() {
    return (
      <div className="introduction justify-center">
      <Helmet>
        <title>BéCé - Login</title>
        <meta property="og:title" content="BéCé - Login" />
        <meta property="og:url" content={`http://www.beceonline.com${this.props.location.pathname}`} />
      </Helmet>
        <div className="container row-responsive">
          <div className="left">
            <img src={Images["testimonial_2"]} alt="Hi" title="Hi" />
          </div>
          <div className="right column justify-center">
            <div className="white-space-64" />
            <h1 className="color-orange" id="to-login">LOGIN</h1>
            <div className="white-space-16" />
            <form className="column" onSubmit={this.login.bind(this)}>
              <h4 className="text-justify">Email:</h4>
              <div className="white-space-8" />
              <input
                type="text"
                name="username"
                className="input input-text"
                required
              />
              <div className="white-space-16" />
              <h4 className="text-justify">Password:</h4>
              <div className="white-space-8" />
              <input
                type="password"
                name="password"
                className="input input-text"
                required
              />
              <div className="white-space-16" />
              <p>
                <i> {this.state.message} </i>
              </p>
              <div className="white-space-16" />
              <div className="btn-container">
                <button className="btn btn-orange" type="submit">
                  <i className="fas fa-sign-in-alt" />
                  &nbsp; CONNECT &nbsp;
                  {this.props.loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : null}
                </button>
              </div>
              <div className="white-space-16" />
              <div>
                <a href="/restore">Forgot your password?</a>
              </div>
              <div className="white-space-16" />
              <p>
                <i>
                  "English is the language with the greatest international
                  presence"
                </i>
              </p>
            </form>

            <div className="white-space-64" />
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    loading: state.login_loading
  };
}
export default connect(mapStateToProps)(Login);
